import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MoneyService {
  // https://vatcalconline.com/
  // https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary

  constructor() {
  }

  removeVat(basePrice: number, vat: number, toFixed = 10): number {
    return +(+basePrice / (1 + +vat)).toFixed(+toFixed);
  }

  calculateVat(basePrice: number, vat: number, toFixed = 10): number {
    let n = (+basePrice / (1 + +vat));
    n = n - basePrice;
    n = n * -1;
    return +n.toFixed(toFixed);
  }

  addVat(basePrice: number, vat: number, toFixed = 10): number {
    return +(+basePrice * (1 + +vat)).toFixed(+toFixed);
  }

  percentToBasicPoints(percent: number, decimals = 3): number {
    if (+percent < 1) {
      // Probably already a BasicPoint
      // TODO: throw exception on debug and beta, this should not happen
      console.log('basic point passed to percentToBasicPoints, this should not happen');
      return +percent.toFixed(decimals);
    }
    const result = +percent / 100;
    return +result.toFixed(decimals);
  }

  basicPointsToPercent(basicPoints: number, decimals = 3): number {
    if (+basicPoints > 1) {
      // Probably already a percent
      console.log('percent passed to basicPointsToPercent, this should not happen');
      return +basicPoints.toFixed(decimals);
    }
    // return +basicPoints * 100;
    const result = +basicPoints * 100;
    return +result.toFixed(decimals);
  }
}
