import {Component, Inject, OnInit, Optional} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Product} from 'src/app/models/pos_product';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {PosProductService} from 'src/app/services/pos-products.service';
import {
  DetailField,
  DetailFieldTypes,
  ItemDetailAbstractComponent
} from '../../components/abstract-item-detail/abstract-item-detail.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {ProgressDialogComponent} from '../../components/dialogs/progress-dialog-component/progress-dialog.component';
import {MoneyService} from '../../services/money.service';
import {UsersService} from '../../services/users.service';
import {PosBrandService} from '../../services/pos-brand.service';
import {PosBrand} from '../../models/pos_brand';
import {PosCategoryProductService} from '../../services/pos-category-product.service';
import {PosCategoryProduct} from '../../models/pos_category_product';

import {
  PrintLabelsDialogComponent
} from '../../components/dialogs/print-labels-dialog-component/print-labels-dialog.component';
import {User} from '../../models/user';
import {HotkeysService} from 'angular2-hotkeys';
import {takeUntil} from 'rxjs/operators';
import {
  TabsUserRelatedComponentsComponent
} from '../../components/tabs-user-related-components/tabs-user-related-components.component';
import {PosProductsListComponent} from '../pos-products-list/pos-products-list.component';
import {
  MoveStockDialogComponent
} from '../../components/dialogs/move-stock-dialog-component/move-stock-dialog.component';


@Component({
  selector: 'app-pos-products-detail',
  templateUrl: '../../components/abstract-item-detail/abstract-item-detail.component.html',
  styleUrls: ['../../components/abstract-item-detail/abstract-item-detail.component.scss']
})
export class PosProductsDetailComponent extends ItemDetailAbstractComponent<Product> implements OnInit {
  // TODO: enable fully barcode scanner if fully is present
  // TODO: check how we can read barcodes on web without fully

  navBase = '/pos/products/';

  printLabelsEnabled = true;
  duplicateObjectEnabled = true;

  editableFields = {
    name: new DetailField('NAME', DetailFieldTypes.text, '', ['start', 0, 59], [Validators.required]),
    size: new DetailField('SIZE', DetailFieldTypes.code, '', ['start', 0, 8]),
    color: new DetailField('COLOR', DetailFieldTypes.code, '', ['start', 0, 8]),
    code: new DetailField('CODE', DetailFieldTypes.code, '', ['start', 0, 25]),

    cost: new DetailField('COST_WO_VAT', DetailFieldTypes.currency, '', ['end', 1, 33]),
    priceRecommended: new DetailField('PVPR_WITH_VAT', DetailFieldTypes.calculated, '', ['end', 1, 33]),
    price: new DetailField('PRICE_WITH_VAT', DetailFieldTypes.calculated, '', ['end', 1, 33]),
    discount: new DetailField('DISCOUNT', DetailFieldTypes.calculated, '', ['end', 1, 33], null, true),
    // TODO: don't put a default VAT, use business default one instead
    vat: new DetailField('VAT', DetailFieldTypes.calculated, 21, ['end', 1, 33]),

    catalogStatus: new DetailField('CATALOG_STATUS', DetailFieldTypes.selector, 'mainc', ['start', 2, 25]),
    catalogStatusName: new DetailField('CATALOG_STATUS', DetailFieldTypes.calculated, '', ['start', 2, 25], null, true),
    stockTracking: new DetailField('TRACK_STOCK', DetailFieldTypes.boolean, false, ['start', 2, 25]),
    stock: new DetailField('STOCK', DetailFieldTypes.number, '', ['start', 2, 25]),
    stockMin: new DetailField('STOCK_MIN', DetailFieldTypes.number, '', ['start', 2, 25]),

    supplierUuid: new DetailField('SUPPLIER', DetailFieldTypes.selector, '', ['start', 3, 50]),
    supplierName: new DetailField('SUPPLIER', DetailFieldTypes.text, '', ['start', 3, 50], null, true),
    supplierCode: new DetailField('SUPPLIER_PRODUCT_CODE', DetailFieldTypes.code, '', ['start', 3, 50]),

    brandUuid: new DetailField('BRAND', DetailFieldTypes.selector, '', ['start', 4, 50]),
    brandName: new DetailField('BRAND', DetailFieldTypes.text, '', ['start', 4, 50], null, true),
    categoryUuid: new DetailField('CATEGORY', DetailFieldTypes.selector, '', ['start', 4, 50]),
    categoryName: new DetailField('CATEGORY', DetailFieldTypes.text, '', ['start', 4, 50], null, true),

    lifetimeDays: new DetailField('LIFETIME_DAYS', DetailFieldTypes.number, '', ['start', 5, 50]),
    lifetimeKm: new DetailField('LIFETIME_KM', DetailFieldTypes.number, '', ['start', 5, 50]),

    woocommercePublished: new DetailField('WOOCOMMERCE', DetailFieldTypes.boolean, false, ['start', 6, 100]),
    prestashopPublished: new DetailField('PRESTASHOP', DetailFieldTypes.boolean, false, ['start', 6, 100]),
    notes: new DetailField('NOTES', DetailFieldTypes.textArea, '', ['start', 7, 100]),

    picture: new DetailField('PICTURE', DetailFieldTypes.picture, '', ['start', 8, 25])
  };

  constructor(
    @Optional() public dialogRef: MatDialogRef<PosProductsDetailComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: { itemId: string, extraMessageI18nKey: string, code: string },
    protected router: Router,
    protected route: ActivatedRoute,
    protected notificationService: CrmNotificationsService,
    protected translate: TranslateService,
    protected formBuilder: UntypedFormBuilder,
    protected dialog: MatDialog,
    protected nativeInterfacesService: NativeInterfacesService,
    protected posProductService: PosProductService,
    protected posBrandService: PosBrandService,
    protected posCategoryProductService: PosCategoryProductService,
    protected moneyService: MoneyService,
    protected usersService: UsersService,
    protected hotkeysService: HotkeysService
  ) {
    super(dialogRef,
      data,
      router,
      route,
      notificationService,
      translate,
      formBuilder,
      dialog,
      posProductService,
      nativeInterfacesService,
      usersService);

    // https://stackoverflow.com/questions/38971660/angular-2-reload-route-on-param-change
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    const defaultVatPercent = Number(this.usersService.business.defaultVat) * 100;
    // TODO: more than a specific variable, we should maybe be able to add generic "action buttons" to the component
    this.moveStockEnabled = this.usersService.business.workshopCount > 1;
    this.editableFields.vat = new DetailField('VAT', DetailFieldTypes.calculated, defaultVatPercent, ['end', 1, 33]);
    if (!this.usersService.business.woocommerceEnabled) {
      delete this.editableFields.woocommercePublished;
    }
    if (!this.usersService.business.prestashopEnabled) {
      delete this.editableFields.prestashopPublished;
    }
    super.ngOnInit();
  }

  onFormReady(): void {
    super.onFormReady();
    if (this.dialogMode && (this.item?.code == null || this.item?.code.toString().length === 0)) {
      const code = this.data.code ? this.data.code : '';
      this.itemForm.get('code').setValue(code);
    }
    if (this.editMode) {
      this.route.params.subscribe((params: Params) => {
        if (params.hasOwnProperty('code')) {
          // tslint:disable-next-line:no-string-literal
          this.itemForm.get('code').setValue(params['code']);
        }
      });
    }
  }

  getSelectFieldOptionsList(field: string): Array<{ value: string, label: string }> {
    // console.log('getSelectFieldOptionsList', field);
    // TODO: show loading indicator while loading options
    if (field in this.selectorFieldsOptions && this.selectorFieldsOptions[field] != null) {
      return this.selectorFieldsOptions[field];
    }
    // TODO: pass i18n keys to the template:
    if (field === 'catalogStatus') {
      this.selectorFieldsOptions[field] = [
        {value: 'mainc', label: this.translate.instant('MAIN_CATALOG')},
        {value: 'ondem', label: this.translate.instant('ON_DEMAND_CATALOG')},
        {value: 'disco', label: this.translate.instant('DISCONTINUED_CATALOG')}
      ];
      return this.selectorFieldsOptions[field];
    }
    if (field === 'supplierUuid') {
      // This line prevents from calling the server multiple times:
      this.selectorFieldsOptions[field] = [];
      this.usersService.getSuppliers().subscribe((suppliers: User[]) => {

        this.selectorFieldsOptions[field] = suppliers.map((supplier: User) => {
          return {value: supplier.id, label: supplier.name};
        });
      });

      return this.selectorFieldsOptions[field];
    }
    if (field === 'brandUuid') {
      // This line prevents from calling the server multiple times:
      this.selectorFieldsOptions[field] = [];
      this.posBrandService.getList({}, '', false).subscribe((brands: PosBrand[]) => {

        this.selectorFieldsOptions[field] = brands.map((brand: PosBrand) => {
          return {value: brand.id, label: brand.name};
        });
      });

      return this.selectorFieldsOptions[field];
    }
    if (field === 'categoryUuid') {
      // This line prevents from calling the server multiple times:
      this.selectorFieldsOptions[field] = [];
      this.posCategoryProductService.getList({}, '', false).subscribe((posCategoryProduct: PosCategoryProduct[]) => {

        this.selectorFieldsOptions[field] = posCategoryProduct.map((category: PosCategoryProduct) => {
          return {value: category.id, label: category.name};
        });
      });

      return this.selectorFieldsOptions[field];
    }
    throw Error(`getSelectFieldOptionsList field: ${field} not found`);
  }

  getInputTypeCalculated(field: string): string {
    // TODO: use fieldTypes object
    if (field === 'price' || field === 'priceRecommended') {
      return 'currency';
    }
    if (field === 'catalogStatusName') {
      return 'text';
    }
    if (field === 'vat') {
      return 'percent';
    }
    if (field === 'discount') {
      // TODO: change to percent
      return 'text';
    }
    throw Error(`getInputTypeCalculated field: ${field} not found`);
  }

  getCalculatedField(field: string): any {
    // User inputs price with vat, we need to push it to the api without VAT, and show it to the user with VAT
    if (field === 'price') {
      return this.moneyService.addVat(this.item.price, this.item.vat, 2);
    }
    if (field === 'priceRecommended') {
      if (!this.item.priceRecommended) {
        return null;
      }
      return this.moneyService.addVat(this.item.priceRecommended, this.item.vat, 2);
    }
    if (field === 'vat') {
      return this.moneyService.basicPointsToPercent(this.item.vat);
    }
    if (field === 'catalogStatusName') {
      const catalogStatus = this.item.catalogStatus;
      if (catalogStatus === 'mainc') {
        return 'MAIN_CATALOG';
      }
      if (catalogStatus === 'ondem') {
        return 'ON_DEMAND_CATALOG';
      }
      if (catalogStatus === 'disco') {
        return 'DISCONTINUED_CATALOG';
      }
    }
    if (field === 'discount') {
      return `${this.item.discountPercent}%`;
    }
    throw Error(`getCalculatedField field: ${field} not found`);
  }

  setCalculatedField(modifiedText: string, field: string): any {
    if (field === 'price' || field === 'priceRecommended') {
      // Abstract use of cleanInputToString?, to automate on an input level?
      modifiedText = this.cleanInputToString(modifiedText, true);
      const f = this.moneyService.removeVat(Number(modifiedText),
        this.moneyService.percentToBasicPoints(Number(this.itemForm.get('vat').value)));
      if (this.item != null) {
        // update
        this.item[field] = f;
      }
      // for create:
      return f;
    }
    if (field === 'vat') {
      const f = this.moneyService.percentToBasicPoints(Number(modifiedText));
      if (this.item != null) {
        // update
        this.item.vat = f;
      }
      // for create:
      return f;
    }
    throw Error(`setCalculatedField field: ${field} not found`);
  }

  addProductByCode(code: string): void {
    // TODO: move to a service to deduplicate (pos-service)?
    let getByCode$;

    const progressDialogRef = this.dialog.open(ProgressDialogComponent, {});
    // TODO: i18n:
    progressDialogRef.componentInstance.titleI18nKey = 'Searching product';
    progressDialogRef.componentInstance.messageI18nKey = '';
    progressDialogRef.componentInstance.onCancel.subscribe(result => {
      if (getByCode$ != null) {
        getByCode$.unsubscribe();
      }
    });

    getByCode$ = this.posProductService.getOrCreateByCode(code).subscribe(r => {
      progressDialogRef.close();
      const product = r.item;
      if (this.dialogMode) {
        this.router.navigate(['/pos/products/' + product.id]);
      } else {
        this.router.navigate(['/pos/products/' + product.id], {queryParams: {edit: 'edit'}});
      }
    }, error => {
      progressDialogRef.close();
      this.router.navigate(['/pos/products/create', {code}]);
    });
  }

  printLabelItem(item: Product, format: 'thermal'): void {
    const dialogRef = this.dialog.open(PrintLabelsDialogComponent, {
      data: {
        product_id: this.itemId,
        format,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });

  }

  moveStockItem(item: Product): void {
    this.dialog.open(MoveStockDialogComponent, {
      // height: '400px',
      // width: '600px',
      data: {product_id: this.itemId, workShopId: item.workshop},
    }).afterClosed().subscribe(result => {
      if (result == null) {
        return;
      }

      // tslint:disable-next-line
      this.posProductService.moveStock(result['productId'], result['workShopId'], result['amount']).subscribe((r) => {
        this.notificationService.successI18N('STOCK_MOVED');
        // TODO: improve, update the data with just a subject or signal? and not request again to the api
        this.item$ = this.posProductService.get(this.itemId);
      });

    });
  }

  isFieldEditable(field: string): boolean {
    if (field === 'stock' && this.itemForm.get('stockTracking').value === false) {
      // console.log('isFieldEditable', field, 'false');
      return false;
    }
    if (field === 'stockMin' && this.itemForm.get('stockTracking').value === false) {
      return false;
    }
    return super.isFieldEditable(field);
  }

  onBarCodeScanner(code: string): void {
    this.addProductByCode(code);
  }

  isKeyboardBarCodeScannerEnabled(): boolean {
    return !this.editMode;
  }

  // configureKeyboardShortcuts(): void {
  //   // Hot Keys:
  //   // https://github.com/brtnshrdr/angular2-hotkeys
  //   this.hotkeysService.reset();
  //   if (this.editMode) {
  //     // No need for ctrl+enter as its the default behavior of the form
  //     // this.hotkeysService.add(
  //     //   new Hotkey(
  //     //     'ctrl+13', // control + enter
  //     //     (): boolean => { // callback function to execute after key combination
  //     //       this.onFormSubmit();
  //     //       return false; // prevent bubbling
  //     //     },
  //     //     undefined, // allow shortcut execution in these html elements
  //     //     'Save' // shortcut name // TODO: translate i18n
  //     //   )
  //     // );
  //     this.hotkeysService.add(
  //       new Hotkey(
  //         'ctrl+a', // control + enter
  //         (): boolean => { // callback function to execute after key combination
  //           this.saveAndAddOther();
  //           return false; // prevent bubbling
  //         },
  //         undefined, // allow shortcut execution in these html elements
  //         'Save and add another' // shortcut name // TODO: translate i18n
  //       )
  //     );
  //   } else {
  //     // not edit mode
  //     this.hotkeysService.add(
  //       new Hotkey(
  //         'e', // control + enter
  //         (): boolean => { // callback function to execute after key combination
  //           this.setEditMode(true);
  //           return false; // prevent bubbling
  //         },
  //         undefined, // allow shortcut execution in these html elements
  //         'Edit' // shortcut name // TODO: translate i18n
  //       )
  //     );
  //   }
  // }

  duplicateObject(item: Product): void {
    this.posProductService.duplicate(item.id).subscribe(r => {
      this.router.navigate(['/pos/products/' + r.item.id], {queryParams: {edit: 'edit'}});
    });
  }

  loadRelatedComponent(): void {
    // we can abstract the takeuntil ondestroy to the abstract parent class
    // Load list of bikes and bss for the client
    // TODO: if its supplier load list of filtered products
    if (!this.item$) {
      return;
    }
    this.item$.pipe(takeUntil(this.onDestroy$)).subscribe((i) => {
      // tslint:disable-next-line
      if (!this.relatedComponentHost) {
        return;
      }
      this.relatedComponentHost.clear();
      this.relatedComponentRef = this.relatedComponentHost.createComponent(TabsUserRelatedComponentsComponent);
      // this.relatedComponentRef = this.viewContainerRef.createComponent(TabsUserRelatedComponentsComponent);
      // TODO: Setting params for the component once is created causes ExpressionChangedAfterItHasBeenCheckedError
      //  on debug mode, we should improve this. maybe with a setter?

      this.relatedComponentRef.instance.tabComponents = [
        // {i18n_label: 'PRODUCT_CTX.VARIANTS', component: PosProductsListComponent, params: {minimal: true, parentId: i.id, parentApiFilter: 'parent_product', defaultFilters: {closed: ''}}},

        {i18n_label: 'CTX_PRODUCT.VARIANTS', component: PosProductsListComponent, params: {
            minimal: true,
            mode: 'variants-list',
            variantBrotherUuid: this.item.id,
            defaultFilters:
              // tslint:disable-next-line
              {parent_product: this.item['parentProductUuid']}}
        },
        // {i18n_label: 'BIKES', component: BikesListComponent, params: {user: i}},
        // {i18n_label: 'SALES', component: PosOrdersListComponent, params: {parentId: i.id, parentApiFilter: 'client__id'}},
        // {i18n_label: 'BUDGETS', component: ServicesheetListComponent, params: {minimal: true, parentId: i.id, parentApiFilter: 'bike__owner__id', viewMode: viewModes.BudgetView, defaultFilters: {budget_status: 'thisisalist,nea,den,app'}}},
      ];
    });
  }
}
